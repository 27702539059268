import { render, staticRenderFns } from "./UserGradeList.vue?vue&type=template&id=605a578a&scoped=true&"
import script from "./UserGradeList.vue?vue&type=script&lang=js&"
export * from "./UserGradeList.vue?vue&type=script&lang=js&"
import style0 from "./UserGradeList.vue?vue&type=style&index=0&id=605a578a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605a578a",
  null
  
)

export default component.exports