<template>
    <v-layout justify-center>
        <v-responsive width="100%" class="px-3 mx-n3">
            <list-heading title="주문 관리" showsFilterButton v-model="showsSearch" />

            <purchase-list-search v-bind="{ showsSearch }" />

            <v-row>
                <v-spacer />
                <v-col cols="auto">
                    <v-sheet outlined rounded>
                        <v-btn color="white" class="green--text px-2" v-bind="{ loading }" @click="excel"><v-icon class="mr-2">mdi-microsoft-excel</v-icon>엑셀다운로드</v-btn>
                    </v-sheet>
                </v-col>
            </v-row>

            <v-data-table v-bind="{ items, headers, loading }" item-class="temp.rowClass" :items-per-page="-1" disable-pagination disable-sort hide-default-footer dense class="v-sheet--outlined">
                <template v-for="(header, headerIndex) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item, index }"> <span :key="headerIndex" v-html="header.formatter.bind(item)(value, item, false, 0 < index ? items[index - 1] : null)" /> </template>
                <template #[`item.numbers`]="{ item }">
                    <div>
                        <order-view :_order="item._order">
                            <template #activator="{ attrs, on }">
                                <v-btn x-small text tile class="pa-0 caption" v-bind="attrs" v-on="on">{{ item.order?.orderNo }}</v-btn>
                            </template>
                        </order-view>
                    </div>
                    <div>{{ item.purchaseNo }}</div>
                </template>
                <template #[`item.order.sender`]="{ item }">
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.name }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.phone }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.email }}</div>
                </template>
                <template #[`item.order.receiver`]="{ item }">
                    <div>{{ item.order.receiver.name }}</div>
                    <div>{{ item.order.receiver.phone }}</div>
                    <div>{{ item.order.receiver.email }}</div>
                </template>
                <template #[`item.order.receiver.address`]="{ item }">
                    <div>[{{ item.order.receiver.postcode }}]</div>
                    <div>{{ item.order.receiver.address1 }} {{ item.order.receiver.address2 }}</div>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { mapGetters } from "vuex";
import { initDataTableHeaders } from "@/assets/variables";
import { decode__productOptionName } from "@/plugins/vue-methods-shop";

import OrderView from "@/components/console/shop/purchases/order-view/order-view.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import PurchaseListSearch from "@/components/console/shop/purchases/purchase-list-search.vue";

export default {
    components: {
        OrderView,
        ListHeading,
        PurchaseListSearch,
    },
    data: () => ({
        purchases: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,
    }),
    computed: {
        ...mapGetters(["getShippingCodeText"]),
        headers() {
            return initDataTableHeaders([
                { width: 170, text: "주문번호\n상품주문번호", value: "numbers", class: "white-space-pre-line" },
                { width: +90, text: "주문일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
                { width: +95, text: "구매확정상태", value: "purchaseStatusMessage", align: "center" },
                { width: +80, text: "주문상태", value: "orderStatusMessage", align: "center" },
                { width: +90, text: "클레임일자", value: "claimedAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
                { width: +90, text: "클레임상태", value: "claimStatusMessage", align: "center" },
                { width: +90, text: "클레임 사유", value: "claimReason", align: "center" },
                { width: 180, text: "클레임 상세사유", value: "claimReasonDetails" },
                { width: 140, text: "배송유형", value: "shippingOption.code", formatter: this.getShippingCodeText },
                { width: 180, text: "배송요청사항", value: "order.requestMessage" },
                { width: 180, text: "상품요청사항", value: "requestMessage" },
                { width: 105, text: "연락처", value: "order.sender.phone" },
                { width: 110, text: "상품코드", value: "code" },
                { width: 180, text: "구매상품", value: "product.name" },
                { width: 180, text: "옵션정보", value: "name", formatter: decode__productOptionName },
                { width: +75, text: "구매수량", value: "amount", align: "center" },
                { width: +90, text: "총 주문\r\n상품금액", value: "purchasePrice__total", formatterType: "number", align: "end" },
                { width: +90, text: "총 주문\r\n결제금액", value: "order.totalPrice", align: "end", formatter: (value, item, isExcel, prev) => (item?._order == prev?._order ? "-" : value?.format?.()) },
                { width: +90, text: "총 주문\r\n배송비 합계", value: "order.deliveryPrice", align: "end", formatter: (value, item, isExcel, prev) => (item?._order == prev?._order ? "-" : ((value || 0) + (item?.order?.servicePrice || 0) + (item?.order?.islandPrice || 0))?.format?.()) },
                { width: 120, text: "구매자", value: "order.sender" },
                { width: 120, text: "수취인", value: "order.receiver" },
                { width: 300, text: "배송지", value: "order.receiver.address" },
            ]).map((item) => ({
                ...item,
                class: (item.class || "") + " px-2",
                cellClass: (item.cellClass || "") + " px-2 py-1 line-height-1-3",
            }));
        },
        items() {
            const colors = ["table-row-grey", null];

            return [...this.purchases].reduce((items, item) => {
                let prev = items.at(-1);
                let isPrevSameOrder = prev?._order == item._order;

                let rowClass = prev?.temp?.rowClass;

                if (!isPrevSameOrder) {
                    let prevColorIndex = colors.indexOf(prev?.temp?.rowClass || null);
                    rowClass = colors[(prevColorIndex + 1) % 2];
                }

                items.push({ ...item, temp: { rowClass } });

                return items;
            }, []);
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, purchases } = await api.console.shop.purchases.getPurchases({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.purchases = purchases;
            } finally {
                this.loading = false;
            }
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                let { purchases } = await api.console.shop.purchases.getPurchases({ params });

                let rows = purchases.map((purchase, index) => {
                    let prev = 0 < index ? purchases[index - 1] : null;
                    let isPrevSameOrder = purchase._order == prev?._order;
                    let showAtPrevNotSameOrder = (value) => (isPrevSameOrder ? "-" : value);
                    return {
                        상품주문번호: purchase.purchaseNo,
                        주문번호: purchase.order.orderNo,
                        구매자명: purchase.order.sender.name,
                        구매자ID: purchase.user?.username ?? "비회원",
                        수취인명: purchase.order.receiver.name,
                        주문상태: purchase.orderStatusMessage,
                        클레임상태: purchase.claimStatusMessage,
                        구매확정상태: purchase.purchaseStatusMessage,
                        결제일: purchase.paymentAt?.toDateTime?.() ?? purchase.createdAt?.toDateTime?.(),
                        배송유형: this.getShippingCodeText(purchase?.shippingOption?.code),
                        배송방법: purchase.delivery.method,
                        택배사: purchase.delivery.name,
                        송장번호: purchase.delivery.number,
                        발송일: purchase.shippedAt?.toDateTime?.(),
                        상품코드: purchase.code,
                        상품명: purchase.product.name,
                        옵션정보: purchase.name,
                        수량: purchase.amount,
                        옵션가격: purchase.price,
                        상품가격: purchase.product?.price,
                        "상품별 할인액": purchase.discountPrice,
                        "총 주문 상품금액": purchase.salePrice,
                        "총 주문 결제금액": showAtPrevNotSameOrder(purchase?.order?.totalPrice || 0),
                        "총 주문 배송비": showAtPrevNotSameOrder(purchase?.order?.deliveryPrice || 0),
                        "총 주문 추가배송비(도서산간)": showAtPrevNotSameOrder(purchase?.order?.islandPrice || 0),
                        "총 주문 추가배송비(설치서비스)": showAtPrevNotSameOrder(purchase?.order?.servicePrice || 0),
                        수취인연락처: purchase.order.receiver.phone,
                        배송지: `${purchase.order.receiver.address1} ${purchase.order.receiver.address2}`,
                        구매자연락처: purchase.order.sender.phone,
                        우편번호: purchase.order.receiver.postcode,
                        배송메시지: purchase.delivery.message,
                        결제수단: purchase.order.paymentMethod,
                    };
                });

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "주문목록.xlsx");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .line-height-1-3 {
        line-height: 1.3;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }

    tr.table-row-grey {
        background-color: rgba(68, 81, 99, 0.06);
    }
}
</style>
