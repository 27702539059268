<template></template>

<script>
import XLSX from "xlsx";

import { DISPLAY_CODES, initShopBulkUploadData, PRODUCT_TYPES } from "@/assets/variables";

const sheetName = "3.제품등록";

const BOOLEANS = { ["Y"]: true, ["N"]: false };

const nameData = (row) => ({
    rawData: row,

    ["카테고리 코드"]: `${row?.A || ""}`?.trim?.() || null,
    ["상품유형"]: `${row?.B || ""}`?.trim?.() || null,
    ["상품명"]: `${row?.C || ""}`?.trim?.() || null,
    ["연관상품코드"]: `${row?.D || ""}`?.trim?.() || null,
    ["상품코드"]: `${row?.E || ""}`?.trim?.() || null,
    ["판매가"]: row?.F || null,
    ["할인가"]: row?.G || null,
    ["재고"]: row?.H || null,
    ["진열순서"]: row?.I || null,
    ["브랜드 코드"]: `${row?.J || ""}`?.trim?.() || null,
    ["제조사"]: `${row?.K || ""}`?.trim?.() || null,
    ["원산지"]: `${row?.L || ""}`?.trim?.() || null,
    ["사이즈"]: `${row?.M || ""}`?.trim?.() || null,
    ["플래그"]: row?.N || null,
    ["해시태그"]: row?.O || null,
    ["전시여부"]: row?.P || null,
    ["판매여부"]: row?.Q || null,
    ["신상품"]: row?.R || null,
    ["베스트 셀러"]: row?.S || null,
    ["이달의 인기상품"]: row?.T || null,
    ["베스트 리뷰"]: row?.U || null,

    ["썸네일"]: row?.V || null,
    ["상품 이미지"]: row?.W || null,
    // ["옵션상품"]: row?.X || null,
    ["필수상품(연동)"]: row?.X || null,
    // ["추가상품"]: row?.Y || null,
    ["추가상품(연동)"]: row?.Y || null,
    ["검색엔진최적화"]: row?.Z || null,
    ["title(seo)"]: row?.AA || null,
    ["author(seo)"]: row?.AB || null,
    ["description(seo)"]: row?.AC || null,
    ["keywords(seo)"]: row?.AD || null,
    // ["상품 상세길이"]: row?.AE || null,
    ["상품 상세 이미지(자동)"]: row?.AF || null,
});

const structureData = ({ rawData, ...nameData }) => ({
    category: nameData["카테고리 코드"],
    type: (Object.values(PRODUCT_TYPES).find(({ text }) => text == nameData["상품유형"]) || PRODUCT_TYPES.NORMAL_PRODUCT).value,
    name: nameData["상품명"],
    relatedGroupCode: nameData["연관상품코드"],
    code: nameData["상품코드"],
    price: nameData["판매가"],
    discountEnabled: nameData["할인가"] != null,
    discount: {
        unit: "won",
        amount: nameData["할인가"] != null ? +nameData["판매가"] - +nameData["할인가"] : 0,
        expiration: {
            enabled: false,
            startDate: null,
            endDate: null,
        },
    },
    stock: nameData["재고"],
    sequence: nameData["진열순서"],
    brand: nameData["브랜드 코드"],
    manufacturer: nameData["제조사"],
    origin: nameData["원산지"],
    size: nameData["사이즈"],
    flags: [...(nameData["플래그"] || "").split(",").map((item) => item.trim())].filter((item) => item),
    hashtags: [...(nameData["해시태그"] || "").split(",").map((item) => item.trim())].filter((item) => item),
    shows: BOOLEANS[nameData["전시여부"]] || false,
    sells: BOOLEANS[nameData["판매여부"]] || false,
    groups: [BOOLEANS[nameData["신상품"]] ? DISPLAY_CODES.NEW_ARRIVAL.value : null, BOOLEANS[nameData["베스트 셀러"]] ? DISPLAY_CODES.BEST_SELLER.value : null, BOOLEANS[nameData["이달의 인기상품"]] ? DISPLAY_CODES.POPULAR.value : null, BOOLEANS[nameData["베스트 리뷰"]] ? DISPLAY_CODES.BEST_REVIEW.value : null].filter((item) => item),
    content: nameData["상품 상세 이미지(자동)"],
    thumb: nameData["썸네일"],
    images: [...(nameData["상품 이미지"] || "").split(",").map((item) => item.trim())].filter((item) => item),
    optionsConf: { enabled: BOOLEANS[nameData["옵션상품"]] || false },
    choiceesConf: { enabled: BOOLEANS[nameData["필수상품(연동)"]] || false },
    relatedsConf: { enabled: BOOLEANS[nameData["추가상품(연동)"]] || false },
    seoEnabled: BOOLEANS[nameData["검색엔진최적화"]] || false,
    seo: {
        title: nameData["title(seo)"],
        meta: [
            { name: "author", content: nameData["author(seo)"] },
            { name: "description", content: nameData["description(seo)"] },
            { name: "keywords", content: nameData["keywords(seo)"] },
        ],
    },

    rawData,
    nameData,
});

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData },
    },
    mounted() {
        this.process();
    },
    watch: {
        file() {
            this.process();
        },
    },
    methods: {
        process() {
            if (!this.file) return;

            const reader = new FileReader();
            reader.onload = async () => {
                const workBook = XLSX.read(reader.result, { type: "binary", sheets: sheetName });
                const sheet = workBook.Sheets[sheetName];
                const rawData = XLSX.utils.sheet_to_json(sheet, { header: "A", range: 4 });

                const products = rawData.map(nameData).map(structureData);

                this.$emit("input", initShopBulkUploadData({ ...this.value, products }));
                this.$emit("loaded");
            };
            reader.readAsBinaryString(this.file);
        },
    },
};
</script>
